import { template as template_c1f0826fea8b416ba08e3f81c8d125a9 } from "@ember/template-compiler";
const FKLabel = template_c1f0826fea8b416ba08e3f81c8d125a9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
